import * as React from 'react';
import './App.css';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import StatusCard from './components/StatusCard.js'
import PatternCard from './components/PatternCard'
import VibrationHistory from './components/VibrationHistory'


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
        Lovense Generator
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function App() {
  return (
    <React.Fragment>
      <Paper>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              Lovense Controller
            </Typography>
          </Toolbar>
        </AppBar>
      </Paper>
      <Container maxWidth="sm">
        <VibrationHistory/>
        <PatternCard/>
        <StatusCard/>
      </Container>
      <Copyright/>
    </React.Fragment>
  );
}

export default App;
