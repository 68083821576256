import { useEffect } from 'react'
import useInterval from '../hooks/useInterval'
import { useDispatch } from 'react-redux'
import { toysUpdated } from '../data/toysSlice'

function LovenseConnection(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://api.lovense.com/api/lan/v1/lan.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useInterval(() => {
    const toys = window.lovense.getToys()
    dispatch(toysUpdated(toys))
  }, 1000)

  return null;
}

export default LovenseConnection