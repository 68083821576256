import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

const toysAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const toysSlice = createSlice({
  name: 'toys',
  initialState: toysAdapter.getInitialState(),
  reducers: {
    toysUpdated: (state, action) => {
      toysAdapter.upsertMany(state, action.payload)
    },
    updatedVibrationLevel: toysAdapter.updateOne,
  },
})

export default toysSlice.reducer


// Action creators are generated for each case reducer function
export const { updatedVibrationLevel, toysUpdated } = toysSlice.actions

export const allToys = toysAdapter.getSelectors((state) => state.toys)

export function setVibrationLevel(toyId, vibrationLevel) {
  return (dispatch, getState) => {
    if (vibrationLevel < 0 || vibrationLevel > 20) {
      return
    }

    window.lovense.sendCommand("Vibrate", { v: vibrationLevel, t: toyId })

    dispatch(updatedVibrationLevel({ id: toyId, changes: { level: vibrationLevel } }))
  }
}