import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import DocumentMeta from 'react-document-meta';
import LovenseConnection from './components/LovenseConnection';
import store from './data/store'
import { Provider } from 'react-redux'

const meta = {
  title: "Lovense Pattern Generator",
}

ReactDOM.render(
  <React.StrictMode>
    <DocumentMeta {...meta} />
    <Provider store={ store }>
      <LovenseConnection/>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
