import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import { useSelector, useDispatch } from 'react-redux';
import useInterval from '../hooks/useInterval';
import { allToys, setVibrationLevel } from '../data/toysSlice'
import { clearLevels, tickLevel } from '../data/levelHistorySlice'

// Algorithm support

function switchUpAndDown(min, max, up, down) {
  return (lastLevel, direction) => {
    if (lastLevel >= max) {
      return -down
    }

    if (lastLevel <= min) {
      return up
    }

    return direction
  }
}

// Algorithms

function stepSweep(min, max, up, down) {
  const updateDirection = switchUpAndDown(min, max, up, down)
  return (lastLevel, direction) => {
    return {nextLevel: Math.max(Math.min(lastLevel+direction, max), min), newDirection: updateDirection(lastLevel, direction)}
  }
}

// Component

function PatternCard() {
  const theme = useTheme()
  const dispatch = useDispatch()

  const initialSweepSettings = {min: 0, max: 5, up: 0.125, down: 5}
  //const initialSweepSettings = {min: 1, max: 5, up: 1, down: 3}
  const initialVariation = stepSweep(initialSweepSettings.min, initialSweepSettings.max, initialSweepSettings.up, initialSweepSettings.down)

  const [lastLevel, setLastLevel] = useState(0)
  const [running, setRunning] = useState(false)
  const [direction, setDirection] = useState(1)
  const [stepSweepSettings, setStepSweepSettings] = useState(initialSweepSettings)
  const [currentAlgorithm, setCurrentAlgorithm] = useState(() => initialVariation)
  const [currentAlgorithmCycleCount, setCurrentAlgorithmCycleCount] = useState(1)
  const [currentAlgorithmChangeCount, setCurrentAlgorithmChangeCount] = useState(1)
  const toys = useSelector(allToys.selectAll)

  const setLevelToZero = () =>  { toys.filter(toy => toy.status).map(toy => dispatch(setVibrationLevel(toy.id, 0))) }

  const play = () => {setRunning(true)}
  const pause = () => {
    setRunning(false) 
    setLevelToZero()
  }
  const stop = () => {
    setRunning(false)
    setLevelToZero()
    setLastLevel(0)
    dispatch(clearLevels())
    setCurrentAlgorithmCycleCount(1)
    setCurrentAlgorithmChangeCount(1)
    setCurrentAlgorithm(() => initialVariation)
  }

  useInterval(() => {
    if (running) {
      const { nextLevel, newDirection } = currentAlgorithm(lastLevel, direction)
      if (direction < 0 && newDirection > 0) { // Transition from down to up
        setCurrentAlgorithmCycleCount(currentAlgorithmCycleCount + 1)
        if (currentAlgorithmCycleCount >= 3) {
          setCurrentAlgorithmCycleCount(1)
          setCurrentAlgorithmChangeCount(currentAlgorithmChangeCount + 1)
          const newStepSweepSettings = { min: Math.min(stepSweepSettings.min+1, 20), max: Math.min(stepSweepSettings.max+1, 20), up: currentAlgorithmChangeCount * 0.125, down: 5 / currentAlgorithmChangeCount }
          setStepSweepSettings(newStepSweepSettings)
          setCurrentAlgorithm(() => stepSweep(newStepSweepSettings.min, newStepSweepSettings.max, newStepSweepSettings.up, newStepSweepSettings.down))
        }
      }
      setDirection(newDirection)
      toys.filter(toy => toy.status).map(toy => dispatch(setVibrationLevel(toy.id, nextLevel)))
      dispatch(tickLevel(nextLevel))
      setLastLevel(nextLevel)
    }
  }, 1000)

  return (
    <Container>
      <Card sx={{ display: 'flex', m: 4, maxWidth: 345 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
              Default Variable Pattern ( {currentAlgorithmCycleCount}, {currentAlgorithmChangeCount})
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            <IconButton aria-label="play" onClick={play}>
              <PlayArrowIcon sx={{ height: 38, width: 38 }} />
            </IconButton>
            <IconButton aria-label="pause" onClick={pause}>
              <PauseIcon />
            </IconButton>
            <IconButton aria-label="stop" onClick={stop}>
              <StopIcon/>
            </IconButton>
          </Box>
        </Box>
        <CardMedia/>
      </Card>
    </Container>
  )
}

export default PatternCard