
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import PauseIcon from '@mui/icons-material/Pause';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import { setVibrationLevel } from '../data/toysSlice'

function ToyControls(toy, online) {
  const dispatch = useDispatch()

  if (online) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        <IconButton aria-label="off" onClick={() => dispatch(setVibrationLevel(toy.id, 0))}>
          <PauseIcon/>
        </IconButton>
        <IconButton aria-label="low" onClick={() => dispatch(setVibrationLevel(toy.id, 5))}>
          <LooksOneIcon/>
        </IconButton>
        <IconButton aria-label="medium" onClick={() => dispatch(setVibrationLevel(toy.id, 10))}>
          <LooksTwoIcon/>
        </IconButton>
        <IconButton aria-label="high" onClick={() => dispatch(setVibrationLevel(toy.id, 15))}>
          <Looks3Icon/>
        </IconButton>
        <IconButton aria-label="ultra" onClick={() => dispatch(setVibrationLevel(toy.id, 20))}>
          <Looks4Icon/>
        </IconButton>
      </Box>
    )
  }
  else {
    return null
  }
}

export default ToyControls