import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { curveBasis } from '@visx/curve'
import { AreaClosed, LinePath, Line } from '@visx/shape'
import { scaleTime, scaleLinear } from '@visx/scale'
import { ParentSize } from '@visx/responsive'
import { GradientPinkRed } from '@visx/gradient'
import { LinearGradient } from '@visx/gradient';
import { genDateValue } from '@visx/mock-data'
import { extent, max } from 'd3-array'
import { Spring } from 'react-spring'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useSelector } from 'react-redux'

export const background = '#3b6978';
export const background2 = '#204051';

function VibrationHistory() {
  const data = useSelector(state => state.levelHistory)
  
  const xScale = scaleLinear({ range: [0, 319], domain: [0, 60], nice: true })

  const yScale = scaleLinear({ range: [305, 0], domain: [0, 20], nice: true })

  return (
    <Container>
      <Card sx={{ display: 'flex', flexDirection: 'column', m: 4, maxWidth: 345 }}>
        <CardContent sx={{ width: 345, height: 345 }}>
          <svg width="100%" height="100%">
            <rect
                  x={0}
                  y={0}
                  width="100%"
                  height="100%"
                  fill="url(#area-background-gradient)"
                />
            <LinearGradient id="area-background-gradient" from={background} to={background2} />
            <GradientPinkRed id="PinkRed" />
            <g>
              <AreaClosed
                data={data}
                yScale={yScale}
                x={(d, i) => xScale(i ?? 0)}
                y={(d) => yScale(d ?? 0)}
                strokeWidth={2}
                stroke={'url(#PinkRed)'}
                fill={'url(#PinkRed)'}
                curve={curveBasis}
              />
            </g>
          </svg>
        </CardContent>
      </Card>
    </Container>
  )
}

export default VibrationHistory