import { createSlice } from '@reduxjs/toolkit'

const lookbackTime = 60

function emptyHistory() {
  const a = new Array(lookbackTime);
  for (let i=0; i<lookbackTime; i++) {
    a[i] = 0
  }
  return a
}

export const levelHistorySlice = createSlice({
  name: 'levelHistory',
  initialState: emptyHistory(),
  reducers: {
    tickLevel: (state, action) => {
      return [...state, action.payload].slice(-lookbackTime)
    },
    clearLevels: () => {
      return emptyHistory()
    }
  },
})

export const {tickLevel, clearLevels } = levelHistorySlice.actions

export default levelHistorySlice.reducer