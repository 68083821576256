import { configureStore } from '@reduxjs/toolkit'
import toysReducer from './toysSlice'
import levelHistoryReducer from './levelHistorySlice'

export default configureStore({
  reducer: {
    toys: toysReducer,
    levelHistory: levelHistoryReducer
  },
})
