import React from 'react';
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { green, red, grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux'
import { allToys } from '../data/toysSlice'
import ToyControls from './ToyControls'

function toyDisplayName(toy) {
  if (toy.nickName) {
    return `${toy.name} (${toy.nickName})`
  } else {
    return toy.name
  }
}

function toyHeader(toy, online) {
  const color = online ? green[500] : red[500]
  const toyStatus = online ? `🔋 ${ toy.battery }%` : "Offline"
  return (<CardHeader avatar={ <Avatar sx={{ bgcolor: color }}>{toy.name[0]}</Avatar> } title={ toyDisplayName(toy) } subheader={ toyStatus } />)
}

function vibrationLevel(toy, online) {
  if (online) {
    return (
      <CardMedia sx={{mx: 1, display: 'flex', flexDirection: 'column'}}>
        <Typography variant="h2" component="div" sx={{ color: grey[500] }}>
          { toy.level || 0 }
        </Typography>
        <Typography variant="text" component="div" sx={{ color: grey[900], ml: 'auto', mt: 'auto', mr: 1, mb: 2 }}>
          /20
        </Typography>
      </CardMedia>
    )
  } else {
    return null
  }
}

function toyCard(toy) {
  const online = toy.status
  return (
    <Card sx={{ m: 4, maxWidth: 345, display: 'flex', justifyContent: 'space-between' }} key={ toy.id } >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        { toyHeader(toy, online) }
        { ToyControls(toy, online) }
      </Box>
      { vibrationLevel(toy, online) }
    </Card>
  )
}

function StatusCard() {
  const toys = useSelector(allToys.selectAll)
  const onlineToys = toys.filter(toy => toy.status)
  const offlineToys = toys.filter(toy => !toy.status)

  return (
    <Container>
      {
        onlineToys.map((toy) => toyCard(toy))
      }
      {
        offlineToys.map((toy) => toyCard(toy))
      }
      <Card sx={{ m: 4, maxWidth: 345 }}>
        <CardContent>
          { onlineToys.length } toys ready, { toys.length } toys found.
        </CardContent>
      </Card>
    </Container>
  );
}

export default StatusCard